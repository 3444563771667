import React, { useState, useEffect, useRef } from "react";
import * as faceapi from "face-api.js";
import { apps } from './config/firebaseAll';
import { auth } from './config/firebase';
import { Link } from 'react-router-dom';

function App() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  let personas = ["unknown"];

  const getCurrentUser = () => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      console.log("Usuario actual: ", auth?.currentUser?.uid);
    });
    return () => unsubscribe();
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    const loadModelsAndStartWebcam = async () => {
      try {
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
        ]);
        console.log("Modelos cargados");
        startWebcam();
      } catch (error) {
        console.error("Error cargando modelos: ", error);
      }
    };

    const startWebcam = () => {
      navigator.mediaDevices
        .getUserMedia({
          video: { width: 320, height: 240 }, // Reducimos la resolución para mejorar la velocidad.
          audio: false,
        })
        .then((stream) => {
          const video = videoRef.current;
          video.srcObject = stream;

          video.addEventListener("playing", () => {
            console.log("Video playing");
            faceRecognition(video);
          });
        })
        .catch((error) => {
          console.error("Error accediendo a la cámara: ", error);
          switch (error.name) {
            case "NotAllowedError":
              alert("Permiso para acceder a la cámara denegado.");
              break;
            case "NotFoundError":
              alert("No se encontró la cámara.");
              break;
            case "NotReadableError":
              alert("La cámara está en uso por otra aplicación.");
              break;
            case "OverconstrainedError":
              alert("Las restricciones especificadas no pueden ser satisfechas por ninguna cámara disponible.");
              break;
            default:
              alert("Ocurrió un error desconocido: " + error.message);
          }
        });
    };

    const fetchFaceData = async () => {
      try {
        const uid = auth.currentUser.uid;
        const collectionRef = apps.firestore().collection("archivos");
        const collectionUserRegistered = apps.firestore().collection("registered_users");
        const docRef = collectionRef.doc(uid);
        const docSnapshot = await docRef.get();
        const fetchDoc = docSnapshot.data();
        const labels = fetchDoc.familyNames;
        personas = labels;

        return Promise.all(
          labels.map(async (label) => {
            const docRefUser = collectionUserRegistered.doc(label);
            const docSnapshotUser = await docRefUser.get();
            const fetchDocUser = docSnapshotUser.data();
            const namePerson = fetchDocUser.nombre;
            const imagePathes = fetchDocUser.imageUrl;
            const descriptions = [];

            for (let i = 0; i < imagePathes.length; i++) {
              const img = await faceapi.fetchImage(imagePathes[i]);
              const detections = await faceapi
                .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions({ inputSize: 160 })) // Reducimos el tamaño de entrada para más velocidad.
                .withFaceLandmarks()
                .withFaceDescriptor();
              
              if (detections && detections.descriptor) {
                descriptions.push(detections.descriptor);
              } else {
                console.warn(`No se detectó ninguna cara en la imagen: ${imagePathes[i]}`);
              }
            }
            return new faceapi.LabeledFaceDescriptors(namePerson, descriptions);
          })
        );
      } catch (error) {
        console.error("Error fetching face data: ", error);
      }
    };

    const faceRecognition = async (video) => {
      const labeledFaceDescriptors = await fetchFaceData();
      const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors);

      const canvas = faceapi.createCanvasFromMedia(video);
      canvas.style.position = "absolute";
      canvas.style.top = 0;
      canvas.style.left = 0;
      canvas.style.zIndex = 1; // Asegura que el canvas esté sobre el video
      video.parentElement.appendChild(canvas);
      canvasRef.current = canvas;

      const displaySize = { width: video.width, height: video.height };
      faceapi.matchDimensions(canvas, displaySize);

      const processFrame = async () => {
        const detections = await faceapi
          .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions({ inputSize: 160, scoreThreshold: 0.5 })) // Ajustamos el tamaño y el umbral para optimizar.
          .withFaceLandmarks()
          .withFaceDescriptors();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);

        const results = resizedDetections.map((d) => faceMatcher.findBestMatch(d.descriptor));

        results.forEach((result, i) => {
          personas.forEach((elemento) => {
            if (elemento === result.label) {
              console.log("Send sms here");
            }
          });
          const box = resizedDetections[i].detection.box;
          const drawBox = new faceapi.draw.DrawBox(box, {
            label: result.toString(),
          });
          drawBox.draw(canvas);
        });

        requestAnimationFrame(processFrame);
      };

      requestAnimationFrame(processFrame);
    };

    loadModelsAndStartWebcam();

    return () => {
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.parentElement.removeChild(canvas);
      }
    };
  }, []);

  return (
    <div>
      <div>
        <nav>
          <ul>
            <Link to={'/'}>Volver a Home</Link>   
          </ul>
        </nav>
      </div>
      <div className="App" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", position: "relative" }}>
        <header className="App-header" style={{ position: "relative" }}>
          <video ref={videoRef} width="600" height="450" autoPlay style={{ position: "relative", zIndex: 0 }}></video>
          <canvas ref={canvasRef} style={{ position: "absolute", top: 0, left: 0 }}></canvas>
        </header>
      </div>
    </div>
  );
}

export default App;
