// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCwlHQkYlF2GynD2YLQnvLkmYLVwLhT93U",
  authDomain: "visionguardvt.firebaseapp.com",
  projectId: "visionguardvt",
  storageBucket: "visionguardvt.appspot.com",
  messagingSenderId: "31296557797",
  appId: "1:31296557797:web:108b5fd9826f91821b3a0c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();
export { auth, app };