import React, { useState } from 'react';
import { auth, googleProvider } from '../config/firebase';
import { apps } from '../config/firebaseAll';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import './Auth.css'; // Importa el archivo CSS

export const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  console.log(auth?.currentUser?.email);

  const signUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {

        const collectionRef = apps.firestore().collection("archivos");

        const nuevoDocumentoData = {
          familyNames: [],
        };
        const idCollection = userCredential.user.uid;
        await collectionRef.doc(idCollection).set(nuevoDocumentoData);

        console.log("Nuevo documento creado correctamente");

        const user = userCredential.user;
        console.log("Usuario creado exitosamente", user);
        alert('Registro exitoso');


      });
    } catch (error) {
      console.error(error);
    }
  };

  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // La autenticación fue exitosa, y userCredential contiene información del usuario
        const user = userCredential.user;
        console.log("Inicio de sesión exitoso", user);
        alert('Inicio de sesión exitoso');
        
        navigate('/facerecognition');
        // Puedes realizar acciones adicionales después del inicio de sesión aquí
      });
    } catch (error) {
      console.error(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      alert('Sign In exitoso');
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      alert('Log Out exitoso');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="auth-container">
      <input
        className="auth-input"
        placeholder="Email..."
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        className="auth-input"
        placeholder="Password..."
        onChange={(e) => setPassword(e.target.value)}
        type="password"
      />
     {/*  <button className="auth-button" onClick={signUp}>
        Sign Up
      </button> */}
      <button className="auth-button" onClick={signIn}>
        Sign In
      </button>
     {/*  <button className="auth-button" onClick={signInWithGoogle}>
        Sign In with Google
      </button> */}
      <button className="auth-button" onClick={logout}>
        Log Out
      </button>
     {/*  <div>
            <nav>
                <ul>
                    <Link to={'/upload'} >Siguiente</Link>   
                </ul>
            </nav>
        </div> */}
    </div>
  );
};
