import firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

export const apps = firebase.initializeApp({
    apiKey: "AIzaSyCwlHQkYlF2GynD2YLQnvLkmYLVwLhT93U",
    authDomain: "visionguardvt.firebaseapp.com",
    projectId: "visionguardvt",
    storageBucket: "visionguardvt.appspot.com",
    messagingSenderId: "31296557797",
    appId: "1:31296557797:web:108b5fd9826f91821b3a0c"
});