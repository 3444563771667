import React from 'react';
import { Auth } from './components/auth';




function Login() {
    return <div className="Login">
         <h1>VisionGuard Login</h1>         
         <div> <Auth/> </div>
          </div>;

}

export default Login;