import React, { useState, useEffect } from 'react';
import { apps } from './config/firebaseAll';
import { auth, app } from './config/firebase'; // Importa el módulo firebase.j
import { Link } from 'react-router-dom';
import './Estilos.css';

const StorageUpload = () => {

  const [archivo, setArchivo] = useState(null);
  const [archivoTwo, setArchivoTwo] = useState(null);
  const [nameImage, setNameImage] = useState("no_name");
  const [currentUser, setCurrentUser] = useState(null);

  const handleArchivoChange = (event) => {
    setArchivo(event.target.files[0]);
  };

  const handleArchivoChangeImg2 = (event) => {
    setArchivoTwo(event.target.files[0]);
  };

  const subirImagen = async () => {
    console.log("archivo", archivo);
    console.log("archivo", archivoTwo);
    if (archivo && archivoTwo) {
      const alas = [nameImage];
      //const rutaCarpeta = 'Tico/';
      const rutaCarpeta = nameImage;
      const uid = await currentUser.uid;

      const storageRef = apps.storage().ref(uid + "/" + rutaCarpeta);
      const archivoPath = storageRef.child("1");
      const archivoPathTwo = storageRef.child("2");
      
      archivoPath.put(archivo).then(async snapshot => {
        const collectionRef = await apps.firestore().collection("archivos");
        const docRef = collectionRef.doc(uid);
        const docData = await docRef.get();
        //const existingFamilyNames = docData.data().familyNames || [];

        await docRef.set({
          familyNames: alas,
        })
        console.log('Archivo 1 subido exitosamente');
      }).catch(error => {
        console.error('Error al subir el archivo: de imagen 1', error);
      });

      archivoPathTwo.put(archivoTwo).then(async snapshot => {
        console.log('Archivo 2 subido exitosamente');
      }).catch(error => {
        console.error('Error al subir el archivo de imagen 2', error);
      }); 

    } else {
      console.warn('Selecciona un archivo antes de subirlo.');
    }
  };

  const getCurrentUser = () => {
    // Configura la escucha del cambio en la autenticación
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      console.log(user.uid);
    });

    // Limpia la suscripción al desmontar el componente
    return () => unsubscribe();
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <div className="container">
      <div className="input-group">
        <input type="file" onChange={handleArchivoChange} />
      </div>
      <div className="input-group">
        <input type="file" onChange={handleArchivoChangeImg2} />
      </div>
      <input className='placeh' placeholder="Nombre de quien son las imagenes" onChange={(e) => setNameImage(e.target.value)} />
      <button className="button" onClick={subirImagen}>Subir Imagen</button>
      <div>
        <nav>
          <ul>
            <li><Link to={'/facerecognition'}>Siguiente</Link></li>
          </ul>
        </nav>
      </div>
      <div>
            <nav>
                <ul>
                    <Link to={'/'} >Volver a Home</Link>   
                </ul>
            </nav>
        </div>
    </div>
  );
};

export default StorageUpload;